import React from "react";
import Panel from "../components/templates/panel";
import Proposal from "../components/templates/proposal";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Ribbon from "../components/templates/ribbon";

import Login from "../components/login";

const IndexPage = () => (
  <Layout>
    <Login />
    <SEO title="Home" />
    <div className="panel">
      <video width="100%" height="100%" autoPlay playsInline muted loop>
        <source src="/images/header-video.mp4" type="video/mp4"></source>
      </video>
      <div className="container panel-container">
        <div className="mobile-hide">
          <div className="purpose">
            <h1>
              Hello Google!<br></br> We are dn&co, a brand and design
              consultancy inspired by culture and place. This is our RFI
              response, we hope you enjoy it.
            </h1>
          </div>
        </div>
      </div>
    </div>

    <Ribbon bgColor="black">
      <div className="mobile-show desktop-hide">
        <h1>Hello Google!</h1>
        <h1>
          We are dn&co, a brand and design consultancy inspired by culture and
          place. This is our RFI response, we hope you enjoy it.
        </h1>
      </div>
      <h1>
        There are three reasons we think we’re the perfect partner for this
        project
      </h1>
    </Ribbon>
    <Panel
      imgSize="100%"
      height={"100%"}
      bg={true}
      imgAlt="dn&co work"
      imgLink={"/images/panel-2.png"}
    >
      <h1>1. We bring purpose to places</h1>
      <p>
        Places with purpose do better because they understand what they’re doing
        for people — whether it’s emboldening a creative community, disrupting
        an entire industry or helping a city find its soul again. We have a
        successful process for identifying your purpose based around
        human-centred needs.
      </p>
    </Panel>
    <Panel
      imgSize="100%"
      height={"100%"}
      bg={true}
      imgAlt="dn&co work"
      imgLink={"/images/panel-3.png"}
    >
      <h1>2. We’re a challenger-partner</h1>
      <p>
        We’ve been instrumental in radically updating the perceptions of places
        and cultural institutions. We strongly believe that every place is and
        should be different. We’re unafraid to work with our clients: asking
        difficult questions and bringing fresh perspectives.
      </p>
    </Panel>
    <Panel
      imgSize="100%"
      height={"100%"}
      bg={true}
      imgAlt="dn&co work"
      imgLink={"/images/panel-4.png"}
    >
      <h1>3. We understand Bay Area dynamics</h1>
      <p>
        There are many large-scale developments in the Bay Area, and our
        research has shown they are often soulless and overly hit by the hipster
        schtick. We know Bay Area developments can do better, and we have a
        track record of tapping into this potential already locally.
      </p>
    </Panel>

    <Proposal fwd={true} linkTo={"about-us"} titleTo={"About Us"} />
  </Layout>
);

export default IndexPage;
